import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import SelectComponent from '../select';
import { PinConfiguration } from '../../interface'
import constants from '../../config/constants';
import Utility from '../../helpers/Utility';

export interface pinConfigFormProps {
    setVisibleClose?: () => void;
    selectedConfig?: PinConfiguration;
    visible: boolean;
    addPinConfiguration: (pinConfig: PinConfiguration) => void;
    updatePinConfiguration: (pinConfig: PinConfiguration) => void;
    pinTypesMap: any;
    pinRepresentationList: any;
}

const types = [
    { _id: 'current', name: 'Current' },
    { _id: 'pressure', name: 'Pressure' },
    { _id: 'temperature', name: 'Temperature' },
    { _id: 'voc', name: 'Voc' },
    { _id: 'vibration', name: 'Vibration' },
    { _id: 'flow', name: 'Flow' },
    { _id: 'switch', name: 'Switch' },
    { _id: 'voltage', name: 'Voltage' },
    { _id: 'humidity', name: 'Humidity' },
    { _id: 'light', name: 'Light' },
    { _id: 'sound', name: 'Sound' }
]

const formatTypes = [
    { _id: 1, name: 'Whole number' },
    { _id: 2, name: 'Round to two decimal' },
    { _id: 3, name: 'ON/OFF' }
]

const pinTypes = [
    { _id: 1, name: 'I2C Pin' },
    { _id: 2, name: 'SPI Pin' },
    { _id: 3, name: 'TH Pin' },
    { _id: 4, name: 'SPI and TH Pin' },
    { _id: 5, name: 'GPIO Common Pin' },
    { _id: 6, name: 'GPIO Pin 1' },
    { _id: 7, name: 'GPIO Pin 2' },
    { _id: 8, name: 'GPIO Pin 3' },
    { _id: 9, name: 'GPIO Pin 4' },
    { _id: 10, name: 'GPIO Pin 5' },
    { _id: 11, name: 'GPIO Pin 6' },
    { _id: 12, name: 'GPIO Pin 7' },
    { _id: 13, name: 'Switch Common Pin' },
    { _id: 14, name: 'Switch 1' },
    { _id: 15, name: 'Switch 2' },
    { _id: 16, name: 'Switch and GPIO Pin' },
    { _id: 17, name: 'Return Humidity' },
    { _id: 18, name: 'Supply Humidity' },
    { _id: 19, name: 'I2C, SPI and TH Pin' }
]

const formatTypesMap = new Map([
    [1, "Whole Number"],
    [2, "Round to two decimal"],
    [3, "ON/OFF"]
])

const combinationPinTypes = [3, 4, 19]

function AddPinConfig(props: pinConfigFormProps) {

    const [isUpdate, setIsUpdate] = useState(false)
    const [isEdit, setIsEdit] = useState(true)
    const [needToSave, setNeedToSave] = useState(false)
    const [display_name, setDisplay_name] = useState('')
    const [selectedConfigId, setSelectedConfigId] = useState('')
    const [type, setType] = useState('')
    const [pinType, setPinType] = useState(0)
    const [pin_format_type, setPinFormatType] = useState(0)
    const [selectedType, setSelectedType] = useState({ name: "Select Type", _id: '' })
    const [selectedFormat, setSelectedFormat] = useState({ name: "Select Format", _id: '' })
    const [selectedPinType, setSelectedPinType] = useState({ name: "Select Pin Type", _id: '' })
    const [selectedCombinationPin, setSelectedCombinationPin] = useState({ name: "Select Combination Pin", _id: '' })
    const [representation, setRepresentation] = useState('')
    const [subType, setSubType] = useState('')
    const [unit, setUnit] = useState('')
    const [errors, setErrors] = useState<any>({})

    useEffect(() => {
        initialSetup();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initialSetup = () => {
        if (props.selectedConfig && props.selectedConfig.hasOwnProperty('_id')) {

            let selectedFormatName: any = formatTypesMap.get(props.selectedConfig?.pin_format_type)
            let selectedPinType: any = props.pinTypesMap.get(props.selectedConfig?.pin_type)
            setIsUpdate(true)
            setIsEdit(false)
            setDisplay_name(props.selectedConfig?.display_name)
            setType((props.selectedConfig?.type))
            setPinFormatType(props.selectedConfig?.pin_format_type)
            setPinType(props.selectedConfig?.pin_type)
            setRepresentation(props.selectedConfig?.representation)
            setSubType(props.selectedConfig?.sub_type)
            setUnit(props.selectedConfig?.unit)
            setSelectedConfigId(props.selectedConfig._id ? props.selectedConfig._id : '')
            setSelectedType({ name: (props.selectedConfig?.type).charAt(0).toUpperCase() + props.selectedConfig?.type.slice(1), _id: (props.selectedConfig?.type) })
            setSelectedFormat({ name: selectedFormatName.toString(), _id: (props.selectedConfig?.pin_format_type).toString() })
            setSelectedPinType({ name: selectedPinType.toString(), _id: (props.selectedConfig?.pin_type).toString() })
            let combinationPin = props.pinRepresentationList?.find((pin: any) => pin._id === props.selectedConfig?.combination_pin)
            setSelectedCombinationPin(combinationPin)

        }
    }

    const saveConfig = () => {


        if (validateField()) {

            if (isUpdate) {

                if (needToSave) {

                    let pinConfig = {
                        _id: selectedConfigId,
                        display_name: display_name.trim(),
                        type: type,
                        representation: representation.trim(),
                        sub_type: subType.trim(),
                        unit: unit.trim(),
                        pin_format_type: pin_format_type,
                        pin_type: pinType,
                        combination_pin : combinationPinTypes.includes(pinType) ? selectedCombinationPin?._id : null
                    }

                    props.updatePinConfiguration(pinConfig)
                } else {
                    closeForm();
                }

            } else {

                let pinConfig = {
                    display_name: display_name.trim(),
                    type: type,
                    representation: representation.trim(),
                    sub_type: subType.trim(),
                    unit: unit.trim(),
                    pin_format_type: pin_format_type,
                    pin_type: pinType,
                    combination_pin : combinationPinTypes.includes(pinType) ? selectedCombinationPin?._id : null
                }
                props.addPinConfiguration(pinConfig)

            }
        }
    }

    const closeForm = () => {
        setDisplay_name('')
        setRepresentation('')
        if (props && props.setVisibleClose) {
            props.setVisibleClose()
        }
    }

    const isDisabled = () => {
        return !isEdit
    }

    const validateField = () => {
        let error: any = {}

        if (!display_name.trim()) {
            error.display_name = constants.message.pinConfig_display_name_required
        } else {
            error.display_name = ''
        }

        if (!type.trim()) {
            error.type = constants.message.pinConfig_type_required
        } else {
            error.type = ''
        }

        if (!representation.trim()) {
            error.representation = constants.message.pinConfig_representation_required
        } else {
            error.representation = ''
        }

        if (!subType.trim()) {
            error.sub_type = constants.message.pinConfig_sub_type_required
        } else {
            error.sub_type = ''
        }

        if (!pinType) {
            error.pin_type = constants.message.pinConfig_pin_type_required
        } else {
            error.pin_type = ''
        }

        if (!pin_format_type) {
            error.pin_format_type = constants.message.pinConfig_format_type_required
        } else {
            error.pin_format_type = ''
        }

        setErrors(error)

        return !(error.display_name || error.type || error.representation || error.pin_format_type
            || error.pin_type || error.sub_type)
    }

    const clearFieldError = (field: string) => {
        let prevErrors = errors
        prevErrors[field] = ''
        setErrors(prevErrors)
    }

    const getSaveButtonDisabledCondition = () => {
        if (!isUpdate) {
            return false
        } else {
            return !(isEdit && display_name && type && representation && subType && pin_format_type && pinType)
        }
    }

    const toggleEdit = () => {
        if (isEdit) {
            initialSetup()
        }
        setIsEdit(!isEdit)
    }

    const showConfirmModal = () => {
        if (props && props.setVisibleClose) {
            props.setVisibleClose()
        }
        Utility.showModal('confirmmodal')
    }

    const handleTypeSelection = (option: any) => {
        clearFieldError('type')
        setType(option._id)
        setSelectedType(option)
        setNeedToSave(true)
    }

    const handlePinTypeSelection = (option: any) => {
        clearFieldError('pin_type')
        setPinType(option._id)
        setSelectedPinType(option)
        setNeedToSave(true)
    }


    const handleFormatSelection = (option: any) => {
        clearFieldError('pin_format_type')
        setPinFormatType(option._id)
        setSelectedFormat(option)
        setNeedToSave(true)
    }

    const handleCombinationSelection = (option: any) => {
        setSelectedCombinationPin(option)
        setNeedToSave(true)
    }

    const supplyAndReturnPinTypes = [17, 18]
    const combinationPinsData = props.pinRepresentationList?.filter((pin: any) => supplyAndReturnPinTypes?.includes(pin?.pin_type))

    return (

        <Modal show={props.visible} onHide={closeForm} size='lg' >
            <Modal.Header className="py-3 px-3 font-weight-bold" closeButton>
                {isUpdate ? "Pin Configuration" : "Add Pin Configuration"}
            </Modal.Header>
            <Modal.Body >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {isUpdate ?
                                <button type="button"
                                    className="btn btn-edit"
                                    onClick={toggleEdit}>
                                    {isEdit ? "Cancel Edit" : "Edit"}
                                </button>
                                : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="input-text-field">
                                <div className="form-group">
                                    <label className="custom-label">Name <span className="mand-star">*</span></label>

                                    <input type="text"
                                        className="form-control"
                                        name="display_name"
                                        value={display_name ? display_name : ''}
                                        onChange={(e) => { clearFieldError('display_name'); setDisplay_name(e.target.value); setNeedToSave(true) }}
                                        maxLength={50}
                                        disabled={isDisabled()}
                                        placeholder="Enter name" />
                                    <div className="error-msg">{errors && errors.display_name ? errors.display_name : ''}</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="input-text-field">
                                <div className="form-group">
                                    <label className="custom-label">Type <span className="mand-star">*</span></label>
                                    <SelectComponent name="type"
                                        isDisabled={isDisabled()}
                                        data={types}
                                        value={selectedType}
                                        onChange={handleTypeSelection}
                                    />
                                    <div className="error-msg">{errors && errors.type ? errors.type : ''}</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="input-text-field">
                                <div className="form-group">
                                    <label className="custom-label">Sub Type <span className="mand-star"> *</span></label>

                                    <input type="sub_type"
                                        className="form-control"
                                        name="sub_type"
                                        value={subType ?? ''}
                                        onChange={(e) => { clearFieldError('sub_type'); setSubType(e.target.value); setNeedToSave(true) }}
                                        maxLength={50}
                                        disabled={isDisabled()}
                                        placeholder={!isUpdate ? "Enter sub type" : ""} />
                                    <div className="error-msg">{errors && errors.sub_type ? errors.sub_type : ''}</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="input-text-field">
                                <div className="form-group">
                                    <label className="custom-label">Representation <span className="mand-star">*</span></label>
                                    <input type="text"
                                        className="form-control"
                                        id=""
                                        aria-describedby=""
                                        value={representation ? representation : ''}
                                        name="representation"
                                        onChange={(e) => { clearFieldError('representation'); setRepresentation(e.target.value); setNeedToSave(true) }}
                                        maxLength={50}
                                        disabled={isDisabled()}
                                        placeholder="Enter representation" />
                                    <div className="error-msg">{errors && errors.representation ? errors.representation : ''}</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="input-text-field">
                                <div className="form-group">
                                    <label className="custom-label">Pin Type <span className="mand-star">*</span></label>
                                    <SelectComponent
                                        isDisabled={isDisabled()}
                                        name="pin_type"
                                        value={selectedPinType}
                                        data={pinTypes}
                                        onChange={handlePinTypeSelection}
                                    />
                                    <div className="error-msg">{errors && errors.pin_type ? errors.pin_type : ''}</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="input-text-field">
                                <div className="form-group">
                                    <label className="custom-label">Unit </label>

                                    <input type="text"
                                        className="form-control"
                                        name="unit"
                                        value={unit ? unit : ''}
                                        onChange={(e) => { clearFieldError('unit'); setUnit(e.target.value); setNeedToSave(true) }}
                                        maxLength={15}
                                        disabled={isDisabled()}
                                        placeholder="Enter unit of measurement" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="input-text-field">
                                <div className="form-group">
                                    <label className="custom-label">Format Type <span className="mand-star">*</span></label>
                                    <SelectComponent name="pin_format_type"
                                        isDisabled={isDisabled()}
                                        data={formatTypes}
                                        value={selectedFormat}
                                        onChange={handleFormatSelection}
                                    />
                                    <div className="error-msg">{errors && errors.pin_format_type ? errors.pin_format_type : ''}</div>
                                </div>
                            </div>
                        </div>

                        { combinationPinTypes.includes(pinType) &&
                        <div className="col-md-6">
                            <div className="input-text-field">
                                <div className="form-group">
                                    <label className="custom-label">Combination Pin</label>
                                    <SelectComponent name="combination_pin"
                                        isDisabled={isDisabled()}
                                        data={combinationPinsData}
                                        value={selectedCombinationPin}
                                        onChange={handleCombinationSelection}
                                    />
                                </div>
                            </div>
                        </div>
                        }

                    </div>
                </div>
            </Modal.Body>
            <div className={selectedConfigId && isEdit ? "modal-footer modal-footer-custom" : "modal-footer"}>
                {selectedConfigId && isEdit ?
                    <button
                        type="button"
                        className="btn btn-inverse-info btn-rounded btn-icon delete-user-icon"
                        onClick={showConfirmModal}
                    >
                        <i className="fa fa-trash-o fa-lg" />
                    </button>
                    : null}

                <button type="button" disabled={getSaveButtonDisabledCondition()} className="btn btn-primary default-btn"
                    onClick={() => saveConfig()}
                >{isUpdate ? "Update" : "Save"}
                </button>
            </div>
        </Modal>
    );
}

export default AddPinConfig
